export const female = {
  Name: 'female',
  Landing: {
    heading: 'Discover how you can lose weight',
    description: 'by solving inflammation issues',
  },
  Quiz: {
    defaultProperties: [
      {
        question: 'gender',
        answer: 'female',
      },
    ],
    quizFlow: [
      {
        question: 'What positive change are you most excited about from losing weight?',
        questionCode: 'weight_loss_outcome_boost',
        answers: [
          'Boosted self-esteem',
          'Moving more easily',
          'Improved appearance',
          'Longer lifespan',
          'Other',
        ],
        type: 'question',
      },
      {
        question: 'Have you tried to lose weight before?',
        questionCode: 'weight_loss_failed_trial_info',
        answers: [
          'Yes, but I only succeeded temporarily',
          'I tried but couldn’t reach my goal',
          'Not yet',
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/different_time_info.png',
        description:
          "<h3>This time, it’s different. We're tackling the root cause.</h3><br />" +
          '<span>Have you ever heard the phrases <strong>“It’s all in your head”</strong> or <strong>“Your gut is your second brain”</strong>?</span><br /><br />' +
          '<span>Stress eating, unusual gut reactions, or feeling butterflies in your stomach when nervous are all signs of the gut-brain connection.</span><br /><br />' +
          '<span><strong>Miscommunication between your subconscious mind, gut, and brain is a major cause of weight gain and poor dietary choices.*</strong></span><br /><br />' +
          '<span>Each month, over 50,000 users start the Hypno self-hypnosis course to rebalance their gut-brain connection, eliminate negative thoughts, and conquer subconscious blocks.</span><br /><br />' +
          '<span><i>"I was amazed at how effective this hypnosis app is."</i> - <strong>Elena, Hypno user since 2023</strong></span>',
        button: 'Got it',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        question: 'Were you referred to Hypno by a nutritionist or therapist?',
        subtitle:
          'Hypnosis is a scientifically-backed method for tackling nutrition-related challenges and mental health. Many therapists recommend Hypno.',
        questionCode: 'referral',
        answers: ['Yes', 'No'],
        type: 'question',
      },
      {
        question: 'What do you think is the main cause of your weight gain?',
        questionCode: 'main_cause',
        answers: [
          { text: '🍔 Unhealthy food', image: '' },
          { text: '😐 Bad eating habits', image: '' },
          { text: '😔 Lack of willpower', image: '' },
          { text: '👵 Menopause', image: '' },
          { text: '🤰 Pregnancy', image: '' },
          { text: '😰 Stress', image: '' },
          { text: '💊 Medication', image: '' },
          { text: '🤔 Other', image: '' },
        ],
        type: 'question',
      },
      {
        question: 'How long have you been dealing with this issue?',
        questionCode: 'struggle_duration',
        answers: ['0-6 months', '6-12 months', '1-5 years', '5+ years'],
        type: 'question',
      },
      {
        question: 'Which physical symptom of extra weight impacts you most?',
        questionCode: 'physical_symptom',
        answers: [
          { text: '😮‍💨 Shortness of breath', image: '' },
          { text: '😴 Snoring', image: '' },
          { text: '🧒 Limited mobility', image: '' },
          { text: '😳 Physical pain', image: '' },
          { text: '🤔 Not sure', image: '' },
        ],
        type: 'question',
      },
      {
        question: 'Has your weight affected other areas of your life?',
        questionCode: 'negative_life_impact',
        subtitle: 'Choose the biggest impact.',
        answers: [
          { text: '😰 Constant stress', image: '' },
          { text: '🔥 Reduced libido', image: '' },
          { text: '❤️‍🩹 Difficulty in romantic connections', image: '' },
          { text: '👯 Challenges socializing', image: '' },
          { text: '🤔 Not sure', image: '' },
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/body_transformation_info/woman.png',
        description:
          '<div style="display:flex;flex-direction:column;gap:8px;align-items:center;margin-bottom:16px;">' +
          '<img src="/static/images/quiz/body_transformation_info/stars.png" style="width:144px;height:24px;margin:0px;padding:0px;" />' +
          '<span style="color:#E2E8F0;font-size:0.875rem;">4 out of 5 users recommend Hypno to friends.</span></div>' +
          '<h3>Body transformation begins in the mind</h3><br />' +
          '<span>Traditional diets and workouts often fall short of creating lasting change. Hypno targets the subconscious roots of weight gain, making it the easiest path to results.</span><br /><br />' +
          '<span>Complete the quiz to start your personalized 21-day hypnotherapy plan:</span>',
        button: 'Got it',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        question: 'What eating habit do you struggle with the most?',
        questionCode: 'bad_eating_habits',
        subtitle: 'Hypno hypnosis will help break this habit.',
        answers: [
          { text: '🗓️ Irregular eating', image: '' },
          { text: '🍪 Binge eating or constant snacking', image: '' },
          { text: '🍲 Large portions', image: '' },
          { text: '🍬 Sugar cravings', image: '' },
          { text: '👩‍🍳 Don’t know how to cook healthy', image: '' },
          { text: '🤔 Other', image: '' },
        ],
        type: 'question',
      },
      {
        question: 'What food craving would you most like to end?',
        questionCode: 'food_craving_biggest_problem',
        subtitle: 'Hypno will focus on blocking this craving.',
        answers: [
          { text: '🍬 Sweets/chocolate', image: '' },
          { text: '🍟 Salty snacks', image: '' },
          { text: '🧀 Dairy', image: '' },
          { text: '🍕 Fast food', image: '' },
          { text: '🥤 Sweet drinks', image: '' },
          { text: '🤔 Other', image: '' },
        ],
        type: 'question',
      },
      {
        question: 'Which belief is holding you back the most from losing weight?',
        questionCode: 'internal_belief',
        subtitle: 'Hypno will help you overcome this belief.',
        answers: [
          { text: '😩 I lack willpower', image: '' },
          { text: '🍽️ I have to finish my plate', image: '' },
          { text: "😐 Why try? I'll just fail", image: '' },
          { text: "🙄 I've always been this way", image: '' },
          { text: '😔 I don’t deserve to get better', image: '' },
          { text: '🤔 Not sure', image: '' },
        ],
        type: 'question',
      },
      {
        question: 'Have you heard of hypnosis helping people change behaviors?',
        questionCode: 'hypnosis_helping_people',
        answers: [
          { text: '👍 Yes', image: '' },
          { text: '🤔 Not sure', image: '' },
        ],
        type: 'question',
      },
      {
        type: 'info',
        image: '/static/images/quiz/help_sequence.png',
        description:
          '<h3>How Hypno Can Help</h3>' +
          '<span>Our personalized hypnosis sessions address the root causes of weight gain by eliminating cravings, breaking bad habits, removing limiting beliefs, and reconnecting your gut-brain axis.</span><br /><br />' +
          "<span>Open Hypno, relax, and let the sessions guide you. It's like losing weight in your sleep.</span><br /><br />" +
          '<span>Research and user data show hypnosis is safe and effective for lasting weight loss.*</span>',
        button: 'Got it',
        imagePosition: 'right',
        maxWidth: '100%',
        width: '100%',
      },
      {
        question: 'How active are you?',
        subtitle: 'Answer a few more questions to see your weight goal timeline.',
        questionCode: 'physical_activity_level',
        answers: [
          { text: '😐 Sedentary (little to no exercise)', image: '' },
          { text: '🚶 Lightly active (exercise once a week)', image: '' },
          { text: '🧘 Moderately active (active job or daily exercise)', image: '' },
          { text: '🏃 Very active (active job or daily 1-hour workout)', image: '' },
          { text: '🏋️ Extremely active (3-5 workouts a week)', image: '' },
        ],
        type: 'question',
      },
      {
        type: 'input',
        heading: 'What is your height?',
        questionCode: 'height_input',
        button: 'Continue',
        param: 'height',
      },
      {
        type: 'input',
        heading: 'What is your current weight?',
        questionCode: 'current_weight_input',
        button: 'Continue',
        param: 'weight',
      },
      {
        type: 'input',
        heading: 'What is your target weight?',
        questionCode: 'desired_weight_input',
        button: 'Continue',
        param: 'desired_weight',
      },
      {
        type: 'input',
        heading: 'What is your age?',
        questionCode: 'age_input',
        button: 'Continue',
        param: 'age',
      },
      {
        question: 'Once you reach your goal, how will you reward yourself?',
        subtitle: 'Use this as motivation during tough times.',
        questionCode: 'reward_protocol',
        answers: [
          { text: '💆 A spa day', image: '' },
          { text: '🙏 A donation to charity', image: '' },
          { text: '✨ A major life change (like a new job)', image: '' },
          { text: '🌴 A dream trip', image: '' },
        ],
        type: 'question',
      },
    ],
  },
  GetStarted: {
    description:
      'Our team has crafted a plan specifically tailored to your parenting needs. Ensure a brighter future for you and your child today.',
    button: 'Continue',
  },
  Summary: {
    heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
    resultsTitle: 'Inner critic score',
    suggestedPlan: 'Parenting empowerment',
    issue: 'parental excellence',
    summary: [
      {
        heading: 'Rejection anxiety',
        description:
          'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
      },
      {
        heading: 'Overwhelming self-doubt',
        description:
          'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
      },
      {
        heading: 'Perfectionism',
        description:
          'It appears that you might be a perfectionist and are never quite satisfied with your work.',
      },
      {
        heading: 'Powerful inner critic',
        description:
          'You frequently criticize yourself harshly for any deviations from your plans.',
      },
    ],
  },
  Purchase: {
    suggestedPlan: 'Self-Improvement',
    promise: 'boost self-esteem',
    subtitle:
      'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
    benefits: [
      {
        heading: 'Build parental confidence',
        description: 'Equip yourself with the tools to face parenting challenges head-on',
      },
      {
        heading: 'Enhance your efficiency',
        description:
          'Master the art of multitasking and time management to be there for your child',
      },
      {
        heading: 'Solidify parental resolve',
        description:
          'Effective strategies for consistent parenting, even when the going gets tough',
      },
      {
        heading: 'Alleviate parental stress',
        description:
          'Methods for managing stress, ensuring a calmer home environment for your child',
      },
      {
        heading: "Become your child's hero",
        description:
          'Long-term strategies to build a stable, loving home for your child to thrive in',
      },
      {
        heading: 'Elevate family dynamics',
        description:
          'Strengthen communication and empathy within the family, creating a harmony',
      },
    ],

    chart: {
      start: 'Your Insecurity Level',
      end: 'Your Target Level',
    },
    newspaper:
      'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
    scienceBacked: [
      {
        image: '/static/icons/cash.png',
        heading: 'Save time and money',
        description:
          'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
      },
      {
        image: '/static/icons/relieved.png',
        heading: 'Increase conversion rate',
        description:
          'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
      },
      {
        image: '/static/icons/bed.png',
        heading: 'Boosted email revenue',
        description:
          'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
      },
      {
        image: '/static/icons/brain.png',
        heading: 'Ad concepts and angles',
        description:
          'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
      },
    ],
  },
};
